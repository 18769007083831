import { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  useToast,
  Skeleton,
  Switch,
  Flex,
} from '@chakra-ui/react';
import InputMask from "react-input-mask";
import { validateCNPJ, validateCPF } from '../../utils/masks';
import axiosServices from '../../services';
import { useLocation, useNavigate } from 'react-router-dom';

export default function ShowUserPage() {
  const navigate = useNavigate();
  const [projectName, setProjectName] = useState("");
  const { proprietaryId } = useLocation().state;
  const toast = useToast();
  const [name, setName] = useState<string>("");
  const [document, setDocument] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingShow, setLoadingShow] = useState(false);
  const [address, setAddress] = useState<string>("");
  const [active, setActive] = useState<boolean>(false);

  const updateProprietary = async () => {
    try {
      setLoading(true);
      await axiosServices.put(`update-proprietary/${proprietaryId}`, {
        projectName,
        name,
        document,
        email,
        whatsApp: phone,
        address,
        active,
      });
      setLoading(false);
      toast({
        title: 'Parabéns',
        description: "Dados atualizados com sucesso",
        status: 'success',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      navigate(-1);
    } catch (error: any) {
      toast({
        title: 'Atenção',
        description: error.response.data.error,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
    }
  }

  const getShowUserData = async () => {
    try {
      setLoadingShow(true);
      const { data } = await axiosServices.get(`proprietary/${proprietaryId}`);
      setDocument(data.data.document);
      setName(data.data.name);
      setPhone(data.data.whatsApp);
      setEmail(data.data.email);
      setAddress(data.data.address)
      setActive(data.data.active);
      setProjectName(data.data.projectName);
      setLoadingShow(false);
    } catch (error: any) {
      toast({
        title: 'Atenção',
        description: error.response.data.error,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      setLoadingShow(false);
    }
  }

  useEffect(() => {
    getShowUserData();
  }, []);

  return (
    <Stack minH={'100vh'} direction={{ base: 'column' }} align={'center'} width="full">
      <Stack marginTop="32" align={'center'}>
      </Stack>
      <Box
        rounded={'lg'}
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow={'lg'}
        width="lg"
        p={6}>
        <Stack spacing={4}>
          {loadingShow ? (
            <Stack direction={{ base: 'column', md: 'column' }} width="full">
              {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                <Skeleton>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                </Skeleton>
              ))}
            </Stack>
          ) : (
            <>
              <FormControl id="name" isRequired>
                <FormLabel>Nome do projeto</FormLabel>
                <Input
                  type="text"
                  value={projectName}
                  required
                  onChange={(event) => setProjectName(event.target.value)}
                />
              </FormControl>
              <FormControl id="name" isRequired>
                <FormLabel>Nome / Nome da empresa</FormLabel>
                <Input
                  type="text"
                  value={name}
                  required
                  onChange={(event) => setName(event.target.value)}
                />
              </FormControl>
              <FormControl id="cpf" isRequired>
                <FormLabel>Cpf / Cnpj</FormLabel>
                <Input
                  maxLength={14}
                  type="text"
                  required
                  value={document}
                  onChange={(event) => {
                    setDocument(event.target.value.replace(/\D/g, ''))
                  }}
                />
              </FormControl>
              <FormControl id="email" isRequired>
                <FormLabel>E-mail</FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                />
              </FormControl>
              <FormControl id="tel">
                <FormLabel>Contato</FormLabel>
                <Input
                  as={InputMask} mask={"(**) *****-****"} maskChar={null}
                  type="text"
                  required
                  value={phone}
                  onChange={(event) => setPhone(event.target.value)}
                />
              </FormControl>
              <FormControl id="address">
                <FormLabel>Endereço</FormLabel>
                <Input
                  type="text"
                  value={address}
                  onChange={(event) => setAddress(event.target.value)}
                />
              </FormControl>
              <Flex display="flex" alignItems="flex-end" width="full">
                <FormControl display='flex' alignItems='flex-end'>
                  <FormLabel htmlFor='active' mb='0'>
                    {active ? "Ativo" : "Inativo"}
                  </FormLabel>
                  <Switch id='active'
                    isChecked={active}
                    onChange={() => setActive(!active)}
                  />
                </FormControl>
              </Flex>
              <Stack spacing={10} pt={2}>
                <Button
                  isDisabled={!document || !name || !email || !projectName || !phone ? true : false}
                  onClick={() => updateProprietary()}
                  isLoading={loading}
                  size="lg"
                  bg={'#0168FF'}
                  color={'white'}
                  _hover={{
                    bg: 'blue.600',
                  }}>
                  Atualizar
                </Button>
              </Stack>
            </>
          )}
        </Stack>
      </Box>
    </Stack>
  );
}
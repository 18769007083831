import {
  Flex,
  Stack,
} from '@chakra-ui/react';
import SideBar from '../../components/sidebar';

export default function HomePage() {
  
  return (
    <>
      <SideBar />
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'} />
      </Stack>
    </>
  );
}
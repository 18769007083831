
import axios, { AxiosRequestConfig } from "axios";
// @ts-ignore
import { Service } from "axios-middleware";
import { environment } from "../environment";

const service = new Service(axios);

const createAxiosInstances = () => {
  const config: AxiosRequestConfig = {
    baseURL: environment.BASE_URL,
  };
  return axios.create(config);
};

const axiosDefault = createAxiosInstances();

service.register({
  async onResponseError(error: any) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        try {
          const token = await new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          originalRequest.headers.Authorization = `Bearer ${token}`;
          return await axios(originalRequest);
        } catch (err) {
          return Promise.reject(err);
        }
      }

      originalRequest._retry = true;
      isRefreshing = true;

      return new Promise((resolve, reject) => {
        const tokenJson = localStorage.getItem("token");
        const tokenParse = tokenJson == null ? null : JSON.parse(tokenJson);
        axiosDefault
          .get(`refreshtoken/${tokenParse.token}`)
          .then(({ data }) => {
            const newToken = {
              token: data?.token,
            };

            localStorage.setItem("token", JSON.stringify(newToken));

            axios.defaults.headers.common.Authorization = `Bearer ${newToken?.token}`;
            originalRequest.headers.Authorization = `Bearer ${newToken?.token}`;
            processQueue(null, newToken?.token);
            resolve(axios(originalRequest));
          })
          .catch((err) => {
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }
    return Promise.reject(error);
  },
});

const requesSuccessHandler = (value: any) => {
  const token = localStorage.getItem("token");


  if (token) {
    value.headers = { Authorization: `Bearer ${token}` };
  }
  return value;
};

const requestErrorHandler = (error: any) => error;

axiosDefault.interceptors.request.use(async (conf) => conf);

axiosDefault.interceptors.request.use(
  requesSuccessHandler,
  requestErrorHandler
);
let isRefreshing = false;

let failedQueue: Array<any> = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

export default axiosDefault;

import React from 'react';
import { Routes, Route } from "react-router-dom";
import Auth from "../pages/auth_page";
import HomePage from '../pages/home_page';
import NewUserPage from '../pages/new_user_page';
import NotFound from '../pages/notFound_page';
import ShowUserPage from '../pages/show_user_page';
import UsersPage from '../pages/users_page';
import PlacePage from '../pages/place_page';
import CountryPage from '../pages/country_page';

export default function Router() {
  return (
      <Routes>
        <Route path='/visualizar-cadastro' element={<ShowUserPage />} />
        <Route path='/criar-projeto' element={<NewUserPage />} />
        <Route path="/listar-projeto" element={<UsersPage />} />
        <Route path="/login" element={<Auth />} />
        <Route path='/dashboard' element={<HomePage />} />
        <Route path='/locais' element={<PlacePage />} />
        <Route path='/visualizar-local' element={<CountryPage />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
  );
}
import React, { useCallback, useEffect, useState } from "react";
import {
  Stack,
  Text,
  Flex,
  Box,
  HStack,
  Tag,
  TagLabel,
  Select,
  Input,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogCloseButton,
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  useDisclosure,
  useToast,
  Skeleton,
} from "@chakra-ui/react";
import axiosServices from "../../services";
import { useLocation } from "react-router-dom";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { orderedByName } from "../../utils/orderByName";

const CountryPage = () => {
  const toast = useToast();
  const {
    isOpen: isOpenDel,
    onOpen: onOpenDel,
    onClose: onCloseDel,
  } = useDisclosure();
  const {
    isOpen: isOpenDistrictDel,
    onOpen: onOpenDistrictDel,
    onClose: onCloseDistrictDel,
  } = useDisclosure();
  const {
    isOpen: isOpenCityDel,
    onOpen: onOpenCityDel,
    onClose: onClosecityDel,
  } = useDisclosure();
  const {
    isOpen: isOpenStateDel,
    onOpen: onOpenStateDel,
    onClose: onCloseStateDel,
  } = useDisclosure();
  const {
    isOpen: isOpenStateEdit,
    onOpen: onOpenStateEdit,
    onClose: onCloseStateEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenCityEdit,
    onOpen: onOpenCityEdit,
    onClose: onCloseCityEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenDistrictEdit,
    onOpen: onOpenDistrictEdit,
    onClose: onCloseDistrictEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenNeighborhoodEdit,
    onOpen: onOpenNeighborhoodEdit,
    onClose: onCloseNeighborhoodEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenPublicPlaceEdit,
    onOpen: onOpenPublicPlaceEdit,
    onClose: onClosePublicPlaceEdit,
  } = useDisclosure();
  const {
    isOpen: isOpenPublicPlaceDel,
    onOpen: onOpenPublicPlaceDel,
    onClose: onClosePublicPlaceDel,
  } = useDisclosure();

  const cancelRef = React.useRef();
  const { id } = useLocation().state;
  const [countries, setCountries] = useState<Array<any>>([]);
  const [resume, setResume] = useState<any | null>(null);
  const [districts, setDistrictsCount] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);
  const [states, setStates] = useState<Array<any>>([]);
  const [neighborhoods, setNeighborhoods] = useState<Array<any>>([]);
  const [search, setSearch] = useState("");
  const [neighborhoodId, setNeighborhoodId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [stateId, setStateId] = useState("");
  const [cityId, setCityId] = useState("");
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [sigla, setSigla] = useState("");
  const [publicPlace, setPublicPlace] = useState<Array<any>>([]);
  const [publicPlaceId, setPublicPlaceId] = useState("");
  const [countryId, setCountryId] = useState("");

  const loadResume = useCallback(async () => {
    setIsLoading(true);
    const { data } = (
      await axiosServices.get(`place/show-resume-coutries/${id}`)
    ).data;
    setResume(data);
    setDistrictsCount(orderedByName(data["districtsCount"]));
    setCities(orderedByName(data["citiesCount"]));
    setStates(orderedByName(data["statesCount"]));
    setNeighborhoods(orderedByName(data["neighborhoodsCount"]));
    setPublicPlace(orderedByName(data["publicPlacesCount"]));
    setCountries([{ _id: data["_id"], name: data["country"] }]);
    setIsLoading(false);
  }, [id]);

  const deleteNeighborhood = async () => {
    try {
      setLoadingDelete(true);
      await axiosServices.delete(`place/delete-neighborhood/${neighborhoodId}`);
      setNeighborhoodId("");
      setLoadingDelete(false);
      onCloseDel();
      loadResume();
      toast({
        description: "Deletado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingDelete(false);
      toast({
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteDistrict = async () => {
    try {
      setLoadingDelete(true);
      await axiosServices.delete(`place/delete-district/${districtId}`);
      setDistrictId("");
      setLoadingDelete(false);
      onCloseDistrictDel();
      loadResume();
      toast({
        description: "Deletado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingDelete(false);
      toast({
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteCity = async () => {
    try {
      setLoadingDelete(true);
      await axiosServices.delete(`place/delete-city/${cityId}`);
      setCityId("");
      setLoadingDelete(false);
      onClosecityDel();
      loadResume();
      toast({
        description: "Deletado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingDelete(false);
      toast({
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deleteState = async () => {
    try {
      setLoadingDelete(true);
      await axiosServices.delete(`place/delete-state/${stateId}`);
      setStateId("");
      setLoadingDelete(false);
      onCloseStateDel();
      loadResume();
      toast({
        description: "Deletado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    } catch (error) {
      setLoadingDelete(false);
      toast({
        description: "Algo deu errado, tente novamente",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateState = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.put(`place/update-state/${stateId}`, {
        name,
        sigla,
      });
      onCloseStateEdit();
      toast({
        description: "Atualizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setName("");
      setSigla("");
      setStateId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao atualizar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateCity = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.put(`place/update-city/${cityId}`, {
        name,
      });
      onCloseCityEdit();
      toast({
        description: "Atualizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setName("");
      setCityId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao atualizar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateDistrict = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.put(`place/update-district/${districtId}`, {
        name,
      });
      onCloseDistrictEdit();
      toast({
        description: "Atualizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setName("");
      setDistrictId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao atualizar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updateNeighborhood = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.put(`place/update-neighborhood/${neighborhoodId}`, {
        name,
      });
      onCloseNeighborhoodEdit();
      toast({
        description: "Atualizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setName("");
      setNeighborhoodId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao atualizar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const updatePublicPlace = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.put(`place/update-street/${publicPlaceId}`, {
        name,
        stateId,
        countryId,
        cityId: cityId || null,
        districtId: districtId || null,
        neighborhoodId
      });
      onClosePublicPlaceEdit();
      toast({
        description: "Atualizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setName("");
      setPublicPlaceId("");
      setDistrictId("");
      setCityId("");
      setNeighborhoodId("");
      setCityId("");
      setStateId("");
      setCountryId("");
      onClosePublicPlaceEdit();
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao atualizar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const deletePublicPlace = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.delete(`place/delete-street/${publicPlaceId}`);
      onClosePublicPlaceDel();
      toast({
        description: "Deletado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setPublicPlaceId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao deletar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    loadResume();
  }, [loadResume]);

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column" }}>
        <Flex flex={1} marginTop="32" ml="10" mr="10" direction="column">
          {isLoading ? (
            <Stack direction={{ base: "column", md: "column" }} width="full">
              <Skeleton height="md">
                <div>contents wrapped</div>
                <div>won't be visible</div>
              </Skeleton>
            </Stack>
          ) : (
            <>
              <Flex
                bgColor="blue.600"
                height="12"
                borderRadius="4"
                justifyContent="space-between"
                alignItems="center"
              >
                <Text ml="2" fontWeight="semibold" color="white">
                  País: {resume?.country}
                </Text>
                <Input
                  height="8"
                  width="50"
                  mr="2"
                  value={search}
                  color="white"
                  borderRadius="20"
                  onChange={(event) => setSearch(event.target.value)}
                  placeholder="Buscar"
                  variant="outline"
                  _placeholder={{ color: "white" }}
                />
              </Flex>
              <Flex mt="5" flexWrap="wrap">
                <Box flex={1} w="100%" padding="4" bgColor="#F9FAFE">
                  <Text fontWeight="medium" mb="3" textDecoration="underline">
                    {
                      states.filter((item) =>
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ).length
                    }{" "}
                    estado(s)
                  </Text>
                  {states
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((it) => {
                      return (
                        <HStack key={it._id} spacing={4} mb="1">
                          <Tag
                            size="md"
                            key={it._id}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="blue"
                          >
                            <TagLabel mr="4">{it.name}</TagLabel>
                            <DeleteIcon
                              cursor="pointer"
                              mr="2"
                              onClick={() => {
                                setStateId(it._id);
                                onOpenStateDel();
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                setName(it.name);
                                setStateId(it._id);
                                setSigla(it.sigla);
                                onOpenStateEdit();
                              }}
                            />
                          </Tag>
                        </HStack>
                      );
                    })}
                </Box>
                <Box flex={1} w="100%" padding="4" bgColor="#F9FAFE">
                  <Text fontWeight="medium" mb="3" textDecoration="underline">
                    {
                      cities.filter((item) =>
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ).length
                    }{" "}
                    cidade(s)
                  </Text>
                  {cities
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((it) => {
                      return (
                        <HStack key={it._id} spacing={4} mb="1">
                          <Tag
                            size="md"
                            key={it._id}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="blue"
                          >
                            <TagLabel mr="4">{it.name}</TagLabel>
                            <DeleteIcon
                              cursor="pointer"
                              mr="2"
                              onClick={() => {
                                onOpenCityDel();
                                setCityId(it._id);
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                setCityId(it._id);
                                setName(it.name);
                                onOpenCityEdit();
                              }}
                            />
                          </Tag>
                        </HStack>
                      );
                    })}
                </Box>
                <Box flex={1} w="100%" padding="4" bgColor="#F9FAFE">
                  <Text fontWeight="medium" mb="3" textDecoration="underline">
                    {
                      districts.filter((item) =>
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ).length
                    }{" "}
                    distrito(s)
                  </Text>
                  {districts
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((it) => {
                      return (
                        <HStack key={it._id} spacing={4} mb="1">
                          <Tag
                            size="md"
                            key={it._id}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="blue"
                          >
                            <TagLabel mr="4">{it.name}</TagLabel>
                            <DeleteIcon
                              cursor="pointer"
                              mr="2"
                              onClick={() => {
                                setDistrictId(it._id);
                                onOpenDistrictDel();
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                setDistrictId(it._id);
                                setName(it.name);
                                onOpenDistrictEdit();
                              }}
                            />
                          </Tag>
                        </HStack>
                      );
                    })}
                </Box>
                <Box flex={1} w="100%" padding="4" bgColor="#F9FAFE">
                  <Text fontWeight="medium" mb="3" textDecoration="underline">
                    {
                      neighborhoods.filter((item) =>
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ).length
                    }{" "}
                    bairro(s)
                  </Text>
                  {neighborhoods
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((it) => {
                      return (
                        <HStack key={it._id} spacing={4} mb="1">
                          <Tag
                            size="md"
                            key={it._id}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="blue"
                          >
                           {it.inUse && (
                              <Box mr={2} p={2} borderRadius="100%" bgColor="#95ef88" />
                            )}
                            <TagLabel textTransform="capitalize" mr="4">
                              {it.name}
                            </TagLabel>
                            <DeleteIcon
                              cursor="pointer"
                              mr="2"
                              onClick={() => {
                                setNeighborhoodId(it._id);
                                onOpenDel();
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                setNeighborhoodId(it._id);
                                setName(it.name);
                                onOpenNeighborhoodEdit();
                              }}
                            />
                          </Tag>
                        </HStack>
                      );
                    })}
                </Box>
                <Box flex={1} w="100%" padding="4" bgColor="#F9FAFE">
                  <Text fontWeight="medium" mb="3" textDecoration="underline">
                    {
                      publicPlace.filter((item) =>
                        item.name.toLowerCase().includes(search.toLowerCase())
                      ).length
                    }{" "}
                    Rua(s)
                  </Text>
                  {publicPlace
                    .filter((item) =>
                      item.name.toLowerCase().includes(search.toLowerCase())
                    )
                    .map((it) => {
                      return (
                        <HStack key={it._id} spacing={4} mb="1">
                          <Tag
                            size="md"
                            key={it._id}
                            borderRadius="full"
                            variant="solid"
                            colorScheme="blue"
                          >
                            {it.inUse && (
                              <Box mr={2} p={2} borderRadius="100%" bgColor="#95ef88" />
                            )}
                            
                            <TagLabel textTransform="capitalize" mr="4">
                              {it.name}
                            </TagLabel>
                            <DeleteIcon
                              cursor="pointer"
                              mr="2"
                              onClick={() => {
                                setPublicPlaceId(it._id);
                                onOpenPublicPlaceDel();
                              }}
                            />
                            <EditIcon
                              onClick={() => {
                                setPublicPlaceId(it._id);
                                setName(it.name);
                                setCountryId(it.countryId);
                                setDistrictId(it?.districtId);
                                setStateId(it?.stateId);
                                setCityId(it?.cityId);
                                setDistrictId(it?.districtId);
                                setNeighborhoodId(it?.neighborhoodId);
                                onOpenPublicPlaceEdit();
                              }}
                            />
                          </Tag>
                        </HStack>
                      );
                    })}
                </Box>
              </Flex>
            </>
          )}
        </Flex>
      </Stack>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseDel();
          setNeighborhoodId("");
        }}
        isOpen={isOpenDel}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Atenção!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>Tem certeza que deseja deletar ?</AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                onCloseDel();
                setNeighborhoodId("");
              }}
              isDisabled={isLoadingDelete}
            >
              Não
            </Button>
            <Button
              isLoading={isLoadingDelete}
              isDisabled={isLoadingDelete}
              onClick={() => deleteNeighborhood()}
              colorScheme="green"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseDistrictDel();
          setDistrictId("");
        }}
        isOpen={isOpenDistrictDel}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Atenção!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontSize="md">
              *Ao deletar o Distrito, iremos deletar todos os bairros!
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                onCloseDistrictDel();
                setDistrictId("");
              }}
              isDisabled={isLoadingDelete}
            >
              Não
            </Button>
            <Button
              isLoading={isLoadingDelete}
              isDisabled={isLoadingDelete}
              onClick={() => deleteDistrict()}
              colorScheme="green"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClosecityDel();
          setCityId("");
        }}
        isOpen={isOpenCityDel}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Atenção!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontSize="md">
              *Ao deletar a Cidade, iremos deletar todos os distritos e bairros!
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                onClosecityDel();
                setCityId("");
              }}
              isDisabled={isLoadingDelete}
            >
              Não
            </Button>
            <Button
              isLoading={isLoadingDelete}
              isDisabled={isLoadingDelete}
              onClick={() => deleteCity()}
              colorScheme="green"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseStateDel();
          setStateId("");
        }}
        isOpen={isOpenStateDel}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Atenção!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontSize="md">
              *Ao deletar o Estado, iremos deletar todas as cidades, distritos e
              bairros!
            </Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                onCloseStateDel();
                setStateId("");
              }}
              isDisabled={isLoadingDelete}
            >
              Não
            </Button>
            <Button
              isLoading={isLoadingDelete}
              isDisabled={isLoadingDelete}
              onClick={() => deleteState()}
              colorScheme="green"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClosePublicPlaceDel();
          setPublicPlaceId("");
        }}
        isOpen={isOpenPublicPlaceDel}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>Atenção!</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Text fontSize="md">Tem certeza que deseja deletar ?</Text>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              colorScheme="red"
              onClick={() => {
                onClosePublicPlaceDel();
                setPublicPlaceId("");
              }}
              isDisabled={isLoadingDelete}
            >
              Não
            </Button>
            <Button
              isLoading={isLoadingDelete}
              isDisabled={isLoadingDelete}
              onClick={() => deletePublicPlace()}
              colorScheme="green"
              ml={3}
            >
              Sim
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      {/* UPDATES */}

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setName("");
          setSigla("");
          setStateId("");
          onCloseStateEdit();
        }}
        isOpen={isOpenStateEdit}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar estado</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Sigla, ex: (MS)"
              value={sigla}
              onChange={(event) => setSigla(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                setName("");
                setSigla("");
                setStateId("");
                onCloseStateEdit();
              }}
              isLoading={saveIsLoading}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isLoading={saveIsLoading}
              isDisabled={!name}
              onClick={() => updateState()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setCityId("");
          setName("");
          onCloseCityEdit();
        }}
        isOpen={isOpenCityEdit}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar cidade</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome da cidade"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setCityId("");
                setName("");
                onCloseCityEdit();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              isDisabled={!name}
              isLoading={saveIsLoading}
              ml="2"
              onClick={() => updateCity()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setDistrictId("");
          setName("");
          onCloseDistrictEdit();
        }}
        isOpen={isOpenDistrictEdit}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar distrito</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome do distrito"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setName("");
                setDistrictId("");
                onCloseDistrictEdit();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isLoading={saveIsLoading}
              isDisabled={!name}
              colorScheme="green"
              onClick={() => updateDistrict()}
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setNeighborhoodId("");
          setName("");
          onCloseNeighborhoodEdit();
        }}
        isOpen={isOpenNeighborhoodEdit}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar bairro</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome do bairro"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setName("");
                setNeighborhoodId("");
                onCloseNeighborhoodEdit();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isDisabled={!name}
              isLoading={saveIsLoading}
              onClick={() => updateNeighborhood()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setName("");
          setPublicPlaceId("");
          setDistrictId("");
          setCityId("");
          setNeighborhoodId("");
          setCityId("");
          setStateId("");
          onClosePublicPlaceEdit();
        }}
        isOpen={isOpenPublicPlaceEdit}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Atualizar Rua</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => {
                setCountryId(event.target.value);
                setCityId("");
                setStateId("");
                setDistrictId("");
                setNeighborhoodId("");
              }}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Select
              value={stateId}
              isDisabled={!countryId}
              onChange={(event) => {
                setStateId(event.target.value);
                setDistrictId("");
                setCityId("");
                setNeighborhoodId("");
              }}
              placeholder="Selecione o Estado"
            >
              {states
                .filter(
                  (it: { countryId: string }) => it.countryId === countryId
                )
                .map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Flex direction="row" alignItems="center">
              <Select
                value={cityId}
                isDisabled={!stateId}
                onChange={(event) => {
                  setCityId(event.target.value);
                  setDistrictId("");
                }}
                placeholder="Selecione a cidade"
              >
                {cities
                  .filter((it) => it.stateId === stateId)
                  .map((it: { name: string; _id: string }) => (
                    <option value={it._id}>{it.name}</option>
                  ))}
              </Select>
              <Flex ml="2" mr="2">
                ou
              </Flex>
              <Select
                isDisabled={!stateId}
                value={districtId}
                onChange={(event) => {
                  setDistrictId(event.target.value);
                  setCityId("");
                }}
                placeholder="Selecione o distrito"
              >
                {districts
                  .filter((it: { stateId: string }) => it.stateId === stateId)
                  .map((it: { name: string; _id: string }) => (
                    <option value={it._id}>{it.name}</option>
                  ))}
              </Select>
            </Flex>
            <Box height="2" />
            <Select
              isDisabled={!cityId && !districtId ? true : false}
              value={neighborhoodId}
              onChange={(event) => {
                setNeighborhoodId(event.target.value);
              }}
              placeholder="Selecione o bairro"
            >
              {neighborhoods.map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome da Rua"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setName("");
                setPublicPlaceId("");
                setDistrictId("");
                setCityId("");
                setNeighborhoodId("");
                setCityId("");
                setStateId("");
                setCountryId("");
                onClosePublicPlaceEdit();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isDisabled={!name}
              isLoading={saveIsLoading}
              onClick={() => updatePublicPlace()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
export default CountryPage;

import { useContext, useState } from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import InputMask from "react-input-mask";
import { validateCNPJ, validateCPF } from '../../utils/masks';
import axiosServices from '../../services';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context';

export default function NewUserPage() {
  const { setPath }: any = useContext(AuthContext);
  const navigate = useNavigate();
  const toast = useToast();
  const [projectName, setProjectName] = useState("");
  const [name, setName] = useState<string>("");
  const [document, setDocument] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [address, setAddress] = useState<string>("");

  const saveProprietary = async () => {
    try {
      setLoading(true);
      await axiosServices.post("create-proprietary", {
        projectName,
        address,
        name,
        document,
        email,
        whatsApp: phone,
        active: true,
        initialDate: moment().format(),
      });
      setLoading(false);
      toast({
        title: 'Parabéns',
        description: "Cadastro realizado com sucesso",
        status: 'success',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      setName("");
      setDocument("");
      setEmail("");
      setPhone("");
      setAddress("");
      localStorage.setItem("label", "LISTAR PROJETOS");
      localStorage.setItem("href", "/listar-projeto");
      setPath({
        label: "LISTAR PROJETOS",
        href: undefined,
        menu: "/listar-projeto",
      });
      navigate("/listar-projeto");
    } catch (error: any) {
      toast({
        title: 'Atenção',
        description: error.response.data.error,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
    }
  }

  return (
    <Stack direction={{ base: 'column' }} align={'center'} width="full">
      <Stack marginTop="28" align={'center'} />
      <Box
        rounded={'lg'}
        bg={useColorModeValue('white', 'gray.700')}
        boxShadow={'lg'}
        width="lg"
        p={6}>
        <Stack spacing={4}>
        <FormControl id="projectName" isRequired>
            <FormLabel>Nome do projeto</FormLabel>
            <Input
              type="text"
              value={projectName}
              required
              onChange={(event) => setProjectName(event.target.value)}
            />
          </FormControl>
          <FormControl id="name" isRequired>
            <FormLabel>Nome / Nome da empresa</FormLabel>
            <Input
              type="text"
              value={name}
              required
              onChange={(event) => setName(event.target.value)}
            />
          </FormControl>
          <FormControl id="cpf" isRequired>
            <FormLabel>Cpf / Cnpj</FormLabel>
            <Input
              maxLength={14}
              type="text"
              value={document}
              required
              onChange={(event) => {
                setDocument(event.target.value.replace(/\D/g, ''))
              }}
            />
          </FormControl>
          <FormControl id="email" isRequired>
            <FormLabel>E-mail</FormLabel>
            <Input
              type="email"
              required
              value={email}
              onChange={(event) => setEmail(event.target.value)}
            />
          </FormControl>
          <FormControl id="tel">
            <FormLabel>WhatsApp</FormLabel>
            <Input
              as={InputMask} mask={"(**) *****-****"} maskChar={null}
              type="text"
              required
              value={phone}
              onChange={(event) => setPhone(event.target.value)}
            />
          </FormControl>
          <FormControl id="address">
            <FormLabel>Endereço</FormLabel>
            <Input
              type="text"
              value={address}
              onChange={(event) => setAddress(event.target.value)}
            />
          </FormControl>
          <Stack spacing={10} pt={2}>
            <Button
              isDisabled={!document || !name || !email || !projectName || !phone  ? true : false}
              onClick={() => saveProprietary()}
              isLoading={loading}
              size="lg"
              bg={'#0168FF'}
              color={'white'}
              _hover={{
                bg: 'blue.600',
              }}>
              Salvar
            </Button>
          </Stack>
        </Stack>
      </Box>
      <Stack marginTop="20" align={'center'} />
    </Stack>
  );
}
import {
  Flex,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Skeleton,
  AlertDialogFooter,
  AlertDialog,
  Button,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogHeader,
  AlertDialogCloseButton,
} from '@chakra-ui/react';
import { IconButton } from '@chakra-ui/react'
import { DeleteIcon, EditIcon } from '@chakra-ui/icons'
import { useEffect, useRef, useState } from 'react';
import axiosServices from '../../services';
import { useToast } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom';

export default function UsersPage() {
  const navigate = useNavigate();
  const [openModalDel, setOpenModalDel] = useState(false);
  const cancelRef = useRef()
  const toast = useToast()
  const [loading, setLoading] = useState(true);
  const [proprietaryId, setProprietaryId] = useState("");
  const [data, setData] = useState<Array<{
    _id: string;
    name: string;
    email: string;
    document: string;
    initialDate: string;
    phone: string;
    whatsApp: string;
    address: string;
    active: boolean;
    projectName: string;
  }>>([]);

  const getUsers = async () => {
    try {
      setLoading(true);
      const { data } = await axiosServices.get("proprietaries");
      setData(data.data.docs);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast({
        title: 'Atenção',
        description: "Erro ao listar as contas",
        status: 'error',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      })
    }
  }

  const deleteUser = async (proprietaryId: string) => {
    try {
      await axiosServices.delete(`proprietary/${proprietaryId}`);
      toast({
        title: 'Parabéns',
        description: "Usuário deletado com sucesso",
        status: 'success',
        duration: 9000,
        position: 'top-right',
        isClosable: true,
      });
      setData(data.filter((it) => it._id !== proprietaryId));
    } catch (error) {
      toast({
        title: 'Atenção',
        description: "Erro ao remover usuário",
        status: 'error',
        duration: 6000,
        position: 'top-right',
        isClosable: true,
      });
      setLoading(false);
    }
  }

  useEffect(() => {
    getUsers();
  }, []);

  return (
    <>
      <Stack minH={'100vh'} direction={{ base: 'column' }} >
        <Flex flex={1} marginTop="32" >
          {loading ? (
            <Stack direction={{ base: 'column', md: 'column' }} width="full" marginRight={10} marginLeft={10}>
              {[1, 2, 3, 4, 5, 6, 7, 8].map(() => (
                <Skeleton>
                  <div>contents wrapped</div>
                  <div>won't be visible</div>
                </Skeleton>
              ))}
            </Stack>
          ) : (
            <TableContainer 
              width="full" 
              marginRight={10}
              marginLeft={10} 
              marginBottom={4}
              rounded={'lg'}
              boxShadow={'lg'}
            >
              <Table variant='simple'>
                <Thead backgroundColor="#D7DDF4">
                  <Tr>
                    <Th>Nome do projeto</Th>
                    <Th>Nome</Th>
                    <Th>WhatsApp</Th>
                    <Th>Situação</Th>
                    <Th isNumeric/>
                  </Tr>
                </Thead>
                {data.map((it) => (
                  <Tbody>
                    <Tr color="black">
                      <Td>{it.projectName || "--"}</Td>
                      <Td>{it.name}</Td>
                      <Td>{it.whatsApp || '--'}</Td>
                      <Td>{it.active  ? "Ativo" : "Intativo"}</Td>
                      <Td isNumeric>
                        <IconButton
                          marginRight={2}
                          aria-label='Delete'
                          icon={<DeleteIcon color="red" />}
                          onClick={() => {
                            setProprietaryId(it._id);
                            setOpenModalDel(true);
                          }}
                        />
                        <IconButton 
                          aria-label='Edit' 
                          icon={<EditIcon color="orange.400" />} 
                          onClick={() => navigate("/visualizar-cadastro", {
                            state: {
                              proprietaryId: it._id
                            }
                          })}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                ))}
              </Table>
            </TableContainer>
          )}
        </Flex>
        <AlertDialog
          motionPreset='slideInBottom'
          // @ts-ignore
          leastDestructiveRef={cancelRef}
          onClose={() => {
            setOpenModalDel(false);
            setProprietaryId("");
          }}
          isOpen={openModalDel}
          isCentered
        >
          <AlertDialogOverlay />
          <AlertDialogContent>
            <AlertDialogHeader>Deseja deletar o usuário?</AlertDialogHeader>
            <AlertDialogCloseButton />
            <AlertDialogBody>
              Ao realizar a operação, será apagado todos os dados do sistema!
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button colorScheme="red" onClick={() => {
                setOpenModalDel(false);
                setProprietaryId("");
              }}>
                Cancelar
              </Button>
              <Button onClick={() => {
                deleteUser(proprietaryId);
                setOpenModalDel(false)
              }} colorScheme='facebook' ml={3}>
                Confirmar
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialog>
      </Stack>
    </>
  );
}
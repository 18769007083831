import {
  Flex,
  Stack,
  Text,
} from '@chakra-ui/react';

export default function NotFound() {
  return (
    <>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Text textAlign="center" fontSize='4xl'>Página não encontrada :(</Text>
        </Flex>
      </Stack>
    </>

  );
}
import React, { useCallback, useEffect, useState } from "react";
import { AddIcon, ViewIcon } from "@chakra-ui/icons";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogCloseButton,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Select,
  Skeleton,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import axiosServices from "../../services";
import { useNavigate } from "react-router-dom";
import { orderedByName } from "../../utils/orderByName";

const PlacePage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenState,
    onOpen: onOpenState,
    onClose: onCloseState,
  } = useDisclosure();
  const {
    isOpen: isOpenCity,
    onOpen: onOpenCity,
    onClose: onCloseCity,
  } = useDisclosure();
  const {
    isOpen: isOpenDistrict,
    onOpen: onOpenDistrict,
    onClose: onCloseDistrict,
  } = useDisclosure();
  const {
    isOpen: isOpenNeighborhood,
    onOpen: onOpenNeighborhood,
    onClose: onCloseNeighborhood,
  } = useDisclosure();
  const {
    isOpen: isOpenPublicPlace,
    onOpen: onOpenPublicPlace,
    onClose: onClosePublicPlace,
  } = useDisclosure();
  const cancelRef = React.useRef();

  const [resume, setResume] = useState([]);
  const [countries, setCountries] = useState<Array<any>>([]);
  const [states, setStates] = useState<Array<any>>([]);
  const [districts, setDistricts] = useState<Array<any>>([]);
  const [cities, setCities] = useState<Array<any>>([]);

  const [countryId, setCountryId] = useState("");
  const [stateId, setStateId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [cityId, setCityId] = useState("");
  const [name, setName] = useState("");
  const [sigla, setSigla] = useState("");
  const [saveIsLoading, setSaveIsLoading] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [neighborhoods, setNeighborhoods] = useState<Array<any>>([]);
  const [neighborhoodId, setNeighborhoodId] = useState("");
  const [isLoadingCities, setIsLoadingCities] = useState(false);
  const [isLoadingNeighborhoods, setIsLoadingNeighborhoods] = useState(false);

  const loadResume = useCallback(async () => {
    setIsloading(true);
    const { data } = (await axiosServices.get("place/resume-coutries")).data;
    setResume(data);
    setIsloading(false);
  }, []);

  const loadAllCountries = useCallback(async () => {
    const { data } = (await axiosServices.get("place/all-coutries")).data;
    setCountries(orderedByName(data));
  }, []);

  const loadStates = useCallback(async () => {
    const { data } = (await axiosServices.get("place/list-all-states")).data;
    setStates(orderedByName(data));
  }, []);

  const loadDistricts = useCallback(async () => {
    const { data } = (
      await axiosServices.get(`place/list-all-districts/${stateId}`)
    ).data;
    setDistricts(orderedByName(data));
  }, [stateId]);

  const loadCities = useCallback(async () => {
    try {
      setIsLoadingCities(true);
      const { data } = (
        await axiosServices.get(`place/list-all-cities/${stateId}`)
      ).data;
      setCities(orderedByName(data));
    } finally {
      setIsLoadingCities(false);
    }
  }, [stateId]);

  const loadNeighborhoods = useCallback(async () => {
    try {
      setIsLoadingNeighborhoods(true);
      const { data } = (
        await axiosServices.get(`place/list-all-neighborhoods/${cityId}`)
      ).data;
      setNeighborhoods(orderedByName(data));
    } finally {
      setIsLoadingNeighborhoods(false);
    }
  }, [cityId]);

  const createState = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.post("place/state-registration", {
        name,
        countryId,
        active: true,
      });
      toast({
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setCountryId("");
      setName("");
      setSigla("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao cadastrar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const createCity = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.post("place/register-city", {
        name,
        stateId,
        countryId,
        active: true,
      });
      toast({
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setCountryId("");
      setName("");
      setStateId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao cadastrar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const createDistrict = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.post("place/register-district", {
        name,
        cityId,
        stateId: cities.find((it) => it._id === cityId).stateId,
        countryId,
        active: true,
      });
      toast({
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setCountryId("");
      setName("");
      setStateId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao cadastrar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const createNeighborhood = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.post("place/register-neighborhood", {
        name,
        stateId,
        countryId,
        cityId,
        active: true,
      });
      toast({
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      setCountryId("");
      setName("");
      setStateId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao cadastrar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const createPublicPlace = async () => {
    try {
      setSaveIsLoading(true);
      await axiosServices.post("place/register-street", {
        name,
        stateId,
        countryId,
        cityId,
        active: true,
        neighborhoodId: neighborhoodId || undefined,
        districtId: districtId || undefined,
      });
      toast({
        description: "Cadastro realizado com sucesso",
        status: "success",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
      onClosePublicPlace();
      setCityId("");
      setName("");
      setDistrictId("");
      setStateId("");
      setName("");
      setCountryId("");
      setNeighborhoodId("");
      setSaveIsLoading(false);
      loadResume();
    } catch (error) {
      setSaveIsLoading(false);
      toast({
        description: "Erro ao cadastrar",
        status: "error",
        duration: 4000,
        position: "top-right",
        isClosable: true,
      });
    }
  };

  const load = useCallback(async () => {
    Promise.all([
      await loadResume(),
      await loadAllCountries(),
      await loadStates(),
    ]);
  }, [loadAllCountries, loadResume, loadStates]);

  useEffect(() => {
    load();
  }, [load]);

  useEffect(() => {
    if (stateId) {
      loadCities();
      loadDistricts();
    }
    if (cityId) {
      loadNeighborhoods();
    }
  }, [
    cityId,
    loadCities,
    loadDistricts,
    loadNeighborhoods,
    neighborhoodId,
    stateId,
  ]);

  const handleNavigation = (id: string) => {
    navigate("/visualizar-local", {
      state: {
        id: id,
      },
    });
  };

  const checkFormPlace = useCallback(() => {
    const notHasDistrict = countryId && stateId && cityId && neighborhoodId && name && !districtId
    const hasDistrict = countryId && stateId && cityId && name && districtId

    if (districtId) {
      if (hasDistrict) {
        return false;
      }
      return true
    }

    if (!districtId) {
      if (notHasDistrict) {
        return false
      }
      return true
    }
              
  }, [cityId, countryId, districtId, name, neighborhoodId, stateId])

  return (
    <>
      <Stack minH={"100vh"} direction={{ base: "column" }}>
        <Flex flex={1} marginTop="32">
          {isLoading ? (
            <Stack
              direction={{ base: "column", md: "column" }}
              width="full"
              marginRight={10}
              marginLeft={10}
            >
              <Skeleton height="md">
                <div>contents wrapped</div>
                <div>won't be visible</div>
              </Skeleton>
            </Stack>
          ) : (
            <TableContainer
              width="full"
              marginRight={10}
              marginLeft={10}
              marginBottom={4}
              rounded={"lg"}
              boxShadow={"lg"}
            >
              <Table variant="simple">
                <Thead backgroundColor="#D7DDF4">
                  <Tr>
                    <Th>País</Th>
                    <Th>Estados</Th>
                    <Th>Cidades</Th>
                    <Th>Distritos</Th>
                    <Th>Bairros</Th>
                    <Th>Ruas</Th>
                    <Th isNumeric>
                      <IconButton
                        marginRight={2}
                        aria-label="Delete"
                        icon={<AddIcon color="blue" />}
                        onClick={() => onOpen()}
                      />
                    </Th>
                  </Tr>
                </Thead>
                {resume.map((it: any) => (
                  <Tbody>
                    <Tr color="black">
                      <Td>{it.country}</Td>
                      <Td>{it.statesCount?.length}</Td>
                      <Td>{it.citiesCount?.length}</Td>
                      <Td>{it.districtsCount?.length}</Td>
                      <Td>{it.neighborhoodsCount?.length}</Td>
                      <Td>{it.publicPlacesCount?.length}</Td>
                      <Td isNumeric>
                        <IconButton
                          marginRight={2}
                          aria-label="Delete"
                          icon={<ViewIcon color="blue" />}
                          onClick={() => handleNavigation(it._id)}
                        />
                      </Td>
                    </Tr>
                  </Tbody>
                ))}
              </Table>
            </TableContainer>
          )}
        </Flex>
      </Stack>
      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>O que você deseja cadastrar?</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Button
              w="full"
              colorScheme="blue"
              onClick={() => {
                onClose();
                onOpenState();
              }}
            >
              Estado
            </Button>
            <Box height="2" />
            <Button
              w="full"
              onClick={() => {
                onClose();
                onOpenCity();
              }}
              colorScheme="blue"
            >
              Cidade
            </Button>
            <Box height="2" />
            <Button
              w="full"
              onClick={() => {
                onClose();
                onOpenDistrict();
              }}
              colorScheme="blue"
            >
              Distrito
            </Button>
            <Box height="2" />
            <Button
              w="full"
              onClick={() => {
                onClose();
                onOpenNeighborhood();
              }}
              colorScheme="blue"
            >
              Bairro
            </Button>
            <Box height="2" />
            <Button
              w="full"
              onClick={() => {
                onClose();
                onOpenPublicPlace();
              }}
              colorScheme="blue"
            >
              Rua
            </Button>
            <Box height="5" />
          </AlertDialogBody>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseState();
          setName("");
          setSigla("");
          setCountryId("");
        }}
        isOpen={isOpenState}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cadastro de Estado</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => setCountryId(event.target.value)}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Sigla, ex: (MS)"
              value={sigla}
              onChange={(event) => setSigla(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              onClick={() => {
                setCountryId("");
                setName("");
                setSigla("");
                onCloseState();
              }}
              isLoading={saveIsLoading}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isLoading={saveIsLoading}
              isDisabled={!countryId || !name}
              onClick={() => createState()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseCity();
          setCountryId("");
          setStateId("");
          setName("");
        }}
        isOpen={isOpenCity}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cadastro de Cidade</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => setCountryId(event.target.value)}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Select
              value={stateId}
              onChange={(event) => setStateId(event.target.value)}
              placeholder="Selecione o Estado"
            >
              {states
                .filter(
                  (it: { countryId: string }) => it.countryId === countryId
                )
                .map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome da cidade"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setStateId("");
                setName("");
                setCountryId("");
                onCloseCity();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              isDisabled={!stateId || !name}
              isLoading={saveIsLoading}
              ml="2"
              onClick={() => createCity()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onCloseDistrict();
          setCityId("");
          setCountryId("");
          setName("");
        }}
        isOpen={isOpenDistrict}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cadastro de Distrito</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => setCountryId(event.target.value)}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Select
              value={stateId}
              onChange={(event) => setStateId(event.target.value)}
              placeholder="Selecione o Estado"
            >
              {states
                .filter(
                  (it: { countryId: string }) => it.countryId === countryId
                )
                .map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Flex>
              {isLoadingCities ? (
                <Box
                  borderRadius={4}
                  borderColor="cbd5e0"
                  borderWidth={1}
                  flex={1}
                  width="full"
                  padding={1.5}
                >
                  <Spinner size="sm" marginLeft={2} />
                </Box>
              ) : (
                <Select
                  value={cityId}
                  onChange={(event) => setCityId(event.target.value)}
                  placeholder="Selecione a cidade do distrito"
                >
                  {cities
                    .filter((it) => it.countryId === countryId)
                    .map((it: { name: string; _id: string }) => (
                      <option value={it._id}>{it.name}</option>
                    ))}
                </Select>
              )}
            </Flex>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome do distrito"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                setCityId("");
                setCountryId("");
                setName("");
                onCloseDistrict();
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isLoading={saveIsLoading}
              isDisabled={!name || !cityId}
              colorScheme="green"
              onClick={() => createDistrict()}
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          setCityId("");
          setName("");
          setDistrictId("");
          setStateId("");
          setName("");
          setCountryId("");
          onCloseNeighborhood();
        }}
        isOpen={isOpenNeighborhood}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cadastro de Bairro</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => {
                setCountryId(event.target.value);
                setCityId("");
                setStateId("");
                setDistrictId("");
              }}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Select
              value={stateId}
              isDisabled={!countryId}
              onChange={(event) => {
                setStateId(event.target.value);
                // setDistrictId("");
                setCityId("");
              }}
              placeholder="Selecione o Estado"
            >
              {states
                .filter(
                  (it: { countryId: string }) => it.countryId === countryId
                )
                .map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Flex direction="row" alignItems="center">
              <Select
                value={cityId}
                isDisabled={!stateId}
                onChange={(event) => {
                  setCityId(event.target.value);
                  // setDistrictId("");
                }}
                placeholder="Selecione a cidade"
              >
                {cities
                  .filter((it) => it.stateId === stateId)
                  .map((it: { name: string; _id: string }) => (
                    <option value={it._id}>{it.name}</option>
                  ))}
              </Select>
            </Flex>
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome do bairro"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                onCloseNeighborhood();
                setCityId("");
                setName("");
                setDistrictId("");
                setStateId("");
                setName("");
                setCountryId("");
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isDisabled={!cityId || !name}
              isLoading={saveIsLoading}
              onClick={() => createNeighborhood()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>

      <AlertDialog
        motionPreset="slideInBottom"
        // @ts-ignore
        leastDestructiveRef={cancelRef}
        onClose={() => {
          onClosePublicPlace();
          setCityId("");
          setName("");
          setDistrictId("");
          setStateId("");
          setName("");
          setCountryId("");
          setNeighborhoodId("");
        }}
        isOpen={isOpenPublicPlace}
        size="4xl"
        isCentered
      >
        <AlertDialogOverlay />
        <AlertDialogContent>
          <AlertDialogHeader>Cadastro de Rua</AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>
            <Select
              required
              value={countryId}
              onChange={(event) => {
                setCountryId(event.target.value);
                setCityId("");
                setStateId("");
                setDistrictId("");
                setNeighborhoodId("");
              }}
              placeholder="Selecione o país"
            >
              {countries.map((it: { name: string; _id: string }) => (
                <option value={it._id}>{it.name}</option>
              ))}
            </Select>
            <Box height="2" />
            <Select
              value={stateId}
              isDisabled={!countryId}
              onChange={(event) => {
                setStateId(event.target.value);
                setDistrictId("");
                setCityId("");
                setNeighborhoodId("");
              }}
              placeholder="Selecione o Estado"
            >
              {states
                .filter(
                  (it: { countryId: string }) => it.countryId === countryId
                )
                .map((it: { name: string; _id: string }) => (
                  <option value={it._id}>{it.name}</option>
                ))}
            </Select>
            <Box height="2" />
            <Flex direction="row" alignItems="center">
              <Select
                value={cityId}
                isDisabled={!stateId}
                onChange={(event) => {
                  setCityId(event.target.value);
                  setNeighborhoodId("");
                }}
                placeholder="Selecione a cidade"
              >
                {cities
                  .filter((it) => it.stateId === stateId)
                  .map((it: { name: string; _id: string }) => (
                    <option value={it._id}>{it.name}</option>
                  ))}
              </Select>
              <Flex ml="2" mr="2">
                ou
              </Flex>
              <Select
                isDisabled={!stateId}
                value={districtId}
                onChange={(event) => {
                  setDistrictId(event.target.value);
                  setNeighborhoodId("");
                }}
                placeholder="Selecione o distrito"
              >
                {districts
                  .filter((it: { stateId: string }) => it.stateId === stateId)
                  .map((it: { name: string; _id: string }) => (
                    <option value={it._id}>{it.name}</option>
                  ))}
              </Select>
            </Flex>
            {cityId && !districtId && (
              <Flex marginTop={2}>
                {isLoadingNeighborhoods ? (
                  <Box
                    borderRadius={4}
                    borderColor="cbd5e0"
                    borderWidth={1}
                    flex={1}
                    width="full"
                    padding={1.5}
                  >
                    <Spinner size="sm" marginLeft={2} />
                  </Box>
                ) : (
                  <>
                    <Box height="2" />
                    <Select
                      isDisabled={!cityId && !districtId ? true : false}
                      value={neighborhoodId}
                      onChange={(event) => {
                        setNeighborhoodId(event.target.value);
                      }}
                      placeholder="Selecione o bairro"
                    >
                      {neighborhoods
                        .map((it: { name: string; _id: string }) => (
                          <option value={it._id}>{it.name}</option>
                        ))}
                    </Select>
                  </>
                )}
              </Flex>
            )}
            <Box height="2" />
            <Input
              variant="outline"
              placeholder="Nome da Rua"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button
              isLoading={saveIsLoading}
              onClick={() => {
                onClosePublicPlace();
                setCityId("");
                setName("");
                setDistrictId("");
                setStateId("");
                setName("");
                setCountryId("");
                setNeighborhoodId("");
              }}
              colorScheme="red"
            >
              Cancelar
            </Button>
            <Button
              ml="2"
              isDisabled={checkFormPlace()}
              isLoading={saveIsLoading}
              onClick={() => createPublicPlace()}
              colorScheme="green"
            >
              Salvar
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};
export default PlacePage;

import {
  Box,
  Flex,
  Text,
  IconButton,
  HStack,
  VStack,
  Stack,
  Collapse,
  Menu,
  MenuButton,
  Avatar,
  MenuDivider,
  MenuList,
  MenuItem,
  Icon,
  Link,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  HamburgerIcon,
  CloseIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  BellIcon
} from '@chakra-ui/icons';
import {
} from '@chakra-ui/icon';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../context';

export default function SideBar() {
  const userName = localStorage.getItem("name")?.split(" ")[0];
  const { setISAuth }: any = useContext(AuthContext);
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();

  const exitApp = () => {
    localStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("name");
    setISAuth(false);
    navigate("/login");
  }

  return (
    <Box position="fixed" width="full" zIndex={1}>
      <Flex
        bg={useColorModeValue('white', 'white')}
        color={useColorModeValue('gray.600', 'white')}
        backgroundColor="#F9FAFE"
        minH={'60px'}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={'solid'}
        borderColor={useColorModeValue('gray.200', 'gray.900')}
        align={'center'}>
        <Flex
          flex={{ base: 1, md: 'auto' }}
          ml={{ base: -2 }}
          display={{ base: 'flex', md: 'none' }}>
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={'ghost'}
            aria-label={'Toggle Navigation'}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: 'center', md: 'start' }}>
          <Text
            textAlign={useBreakpointValue({ base: 'center', md: 'left' })}
            fontFamily={'heading'}
            color={useColorModeValue('gray.800', 'white')}>
          </Text>

          <Flex display={{ base: 'none', md: 'flex' }} ml={10}>
            <DesktopNav />
          </Flex>
        </Flex>
        <Box marginRight={4}>
          <BellIcon />
        </Box>
        <Box
          borderWidth={1}
          borderColor="grey.50"
          backgroundColor="#E3E8F4"
          paddingLeft={6}
          paddingRight={6}
          borderRadius={20}

        >
          <Stack
            justify={'flex-end'}
            direction={'row'}
            spacing={6}>
            <Flex alignItems={'center'}>
              <Menu>
                <MenuButton
                  py={2}
                  transition="all 0.3s"
                  _focus={{ boxShadow: 'none' }}>
                  <HStack>
                    <Avatar
                      size={'sm'}
                      src={
                        'https://www.clipartmax.com/png/middle/296-2969961_no-image-user-profile-icon.png'
                      }
                    />
                    <VStack
                      display={{ base: 'none', md: 'flex' }}
                      alignItems="flex-start"
                      spacing="1px">
                      <Text color="black" fontSize="sm">{userName}</Text>
                      <Text fontSize="xs" color="gray.600">
                        Administrador
                      </Text>
                    </VStack>
                  </HStack>
                </MenuButton>
                <MenuList
                  bg={useColorModeValue('white', 'gray.900')}
                  borderColor={useColorModeValue('gray.200', 'gray.700')}>
                  <MenuDivider />
                  <MenuItem onClick={() => exitApp()}>Sair</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Stack>
        </Box>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav />
      </Collapse>
    </Box>
  );
}

const DesktopNav = () => {
  const { path, setPath }: any = useContext(AuthContext);
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const navigate = useNavigate();

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger={'hover'} placement={'bottom-start'}>
            <Box>
              <PopoverTrigger>
                <Link
                  marginTop={10}
                  onClick={() => {
                    if ((navItem.href?.includes("/"))) {
                      setPath({
                        label: navItem.label,
                        href: undefined,
                        menu: navItem.label,
                      });
                      navigate(navItem.href);
                    }
                  }}
                  p={2}
                  fontSize={'sm'}
                  fontWeight={500}
                  textDecoration=''
                  borderRadius={4}
                  paddingLeft="7"
                  paddingTop="2"
                  paddingBottom="2"
                  paddingRight="7"
                  backgroundColor={path.menu === navItem.label ? "#E3E8F4" : "transparent"}
                  color={"#24335A"}
                  _hover={{
                    textDecoration: 'none',
                    color: "grey",
                  }}>
                  {navItem.label}
                </Link>
              </PopoverTrigger>
            </Box>

            {navItem.children && (
              <PopoverContent
                border={0}
                boxShadow={'xl'}
                bg={popoverContentBgColor}
                p={4}
                rounded={'xl'}
                minW={'sm'}>
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const DesktopSubNav = ({ label, href, subLabel, menu }: NavItem) => {
  const navigate = useNavigate();
  const { setPath, path }: any = useContext(AuthContext);

  return (
    <Box
      onClick={() => {
        if (href) {
          setPath({
            label: label,
            href: href,
            menu: menu,
          });
          localStorage.setItem("label", label);
          localStorage.setItem("href", href);
          navigate(href)
        }
      }}
      role={'group'}
      display={'block'}
      p={2}
      backgroundColor={label === path.label ? '#F9FAFE' : 'transparent'}
      rounded={'md'}
      _hover={{ bg: useColorModeValue('#F9FAFE', 'gray.900') }}>
      <Stack direction={'row'} align={'center'}>
        <Box>
          <Text
            fontSize={'sm'}
            transition={'all .3s ease'}
            _groupHover={{ color: 'black' }}
            fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={'sm'}>{subLabel}</Text>
        </Box>
        <Flex
          transition={'all .3s ease'}
          transform={'translateX(-10px)'}
          opacity={0}
          _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
          justify={'flex-end'}
          align={'center'}
          flex={1}>
          <Icon color={'black'} w={5} h={5} as={ChevronRightIcon} />
        </Flex>
      </Stack>
    </Box>
  );
};

const MobileNav = () => {
  return (
    <Stack
      bg={useColorModeValue('white', 'gray.800')}
      p={4}
      display={{ md: 'none' }}>
      {NAV_ITEMS.map((navItem) => (
        <MobileNavItem key={navItem.label} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, children, href }: NavItem) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={href ?? '#'}
        justify={'space-between'}
        align={'center'}
        _hover={{
          textDecoration: 'none',
        }}>
        <Text
          fontWeight={600}
          color={useColorModeValue('gray.600', 'gray.200')}>
          {label}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: '0!important' }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle={'solid'}
          borderColor={useColorModeValue('gray.200', 'gray.700')}
          align={'start'}>
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  menu?: string;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: 'DASHBOARD',
    href: '/dashboard',
  },
  {
    label: 'PROJETOS',
    href: 'PROJETOS',
    children: [
      {
        menu: 'PROJETOS',
        label: 'CADASTRAR PROJETO',
        href: '/criar-projeto',
      },
      {
        menu: 'PROJETOS',
        label: 'LISTAR PROJETOS',
        href: '/listar-projeto',
      },
    ],
  },
  {
    label: 'LOCAIS',
    href: '/locais',
  },
];
import { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext({});

interface Props {
  label: string | null,
  href: string | null,
  menu: string | null,
}

function AuthProvider({ children }: any) {
  useEffect(() => {
    if (localStorage.getItem("label") && localStorage.getItem("href")) {
      setPath({
        label: localStorage.getItem("label"),
        href: localStorage.getItem("href"),
        menu: localStorage.getItem("menu")
      });
    }
  }, []);

  const [isAuth, setISAuth] = useState<boolean>(localStorage.getItem("token") ? true : false);
  const [path, setPath] = useState<Props>({
    label: '',
    href: '',
    menu: ''
  });

  return (
    <AuthContext.Provider value={{ isAuth, setISAuth, setPath, path }}>
      {children}
    </AuthContext.Provider>
  );
}
export default AuthProvider;

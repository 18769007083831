import { useContext } from 'react';
import SideBar from './components/sidebar';
import { AuthContext } from './context';
import Router from './router';

function App() {
  const {isAuth}: any = useContext(AuthContext);
  return (
    <>
      {isAuth ? <SideBar /> : null}
      <Router />
    </>
  )
}

export default App;

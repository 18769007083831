import { useContext, useEffect, useState } from 'react';
import {
  Button,
  Card,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import axiosServices from '../../services';
import { useToast } from '@chakra-ui/react'
import { AuthContext } from '../../context';


export default function Auth() {
  const { setISAuth }: any = useContext(AuthContext);
  const toast = useToast()
  const [email, setEmail] = useState<string>('');
  const [password, setPasswrod] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/dashboard');
    }
  }, []);

  const auth = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { data } = await axiosServices.post("auth-v2", { password, email });

      localStorage.setItem("token", data.token);
      localStorage.setItem("name", data.account.name);

      setISAuth(true);
      navigate("/dashboard");
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast({
        title: 'Atenção',
        description: error?.response?.data.error,
        status: 'error',
        duration: 6000,
        position: 'top-right',
        isClosable: true,
      });
    }
  }

  return (
    <>
      <Stack minH={'100vh'} direction={{ base: 'column', md: 'row' }}>
        <Flex p={8} flex={1} align={'center'} justify={'center'}>
          <Card width="lg" padding={8}>
            <Stack spacing={4} w={'full'} maxW={'md'}>
              <Heading fontSize={'2xl'} textAlign="center">Bem-vindo</Heading>
              <form onSubmit={auth}>
                <FormControl id="email">
                  <FormLabel>E-mail</FormLabel>
                  <Input
                    value={email}
                    type="email"
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </FormControl>
                <FormControl id="password">
                  <FormLabel>Senha</FormLabel>
                  <Input
                    value={password}
                    type="password"
                    onChange={(event) => setPasswrod(event.target.value)}
                  />
                </FormControl>
                <Stack spacing={2}>
                  <br />
                  <Button
                    colorScheme={'blue'}
                    variant={'solid'}
                    type="submit"
                    isLoading={loading}
                  >
                    Acessar
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Card>
        </Flex>
      </Stack>
    </>
  );
}